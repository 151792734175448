import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { isLoggedInFullUser } from '../../../util/auth0/user.util';
import { isAbsoluteUrl } from '../../../util/url.util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { WEBSITE_URL } from '../../../constant/url.const';
import { BREAKPOINT } from '../../../constant/app.constant';

const _IconButton = styled(IconButton)`
  width: 4.8rem;
  height: 4.8rem;

  ${({ theme }) => `
    ${theme.breakpoints.up(BREAKPOINT.layoutDesktop)} {
      visibility: hidden;
    }
  `}
`;

const buttonFontSize = '25px';

interface ToolbarStyleProps {
  showBackNav: boolean;
  showLogOut: boolean;
}

const _header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem 1rem 1rem;
`;

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  backNav: {
    visibility: (props: ToolbarStyleProps) => (props.showBackNav ? 'visible' : 'hidden'),
    color: '#228B22',
    fontSize: buttonFontSize,
  },
  logo: {
    height: '32px',
  },
  hamburgerSpan: {
    visibility: (props: ToolbarStyleProps) => (props.showLogOut ? 'visible' : 'hidden'),
  },
  hamburgerMenu: {
    color: '#228B22',
    fontSize: buttonFontSize,
  },
});

interface Props {
  handleMenuToggle: (isOpen: boolean) => void;
  backNavTarget?: string;
}

export const Header = ({ handleMenuToggle, backNavTarget }: Props) => {
  const { user } = useAuth0();
  const classes = useStyles({
    showBackNav: backNavTarget ? true : false,
    showLogOut: isLoggedInFullUser(user),
  });

  const renderBackNav = () => {
    const backNavButton = (
      <IconButton>
        <FontAwesomeIcon className={classes.backNav} icon={faArrowLeft} />
      </IconButton>
    );

    if (!backNavTarget) {
      // return an unlinked back nav button, which will have the CSS prop "visibility: 'hidden'" but take up
      // space to keep the flex row spacing
      return backNavButton;
    }

    if (isAbsoluteUrl(backNavTarget)) {
      return <a href={backNavTarget}>{backNavButton}</a>;
    }

    return <Link to={backNavTarget}>{backNavButton}</Link>;
  };

  const handleClick = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    handleMenuToggle(true);
  };

  return (
    <>
      <_header>
        {renderBackNav()}
        <a href={WEBSITE_URL}>
          <img
            className={classes.logo}
            src="https://cdn.offsetter.io/logo/offsetter-logo-horizontal-green.svg"
            alt="Offsetter logo"
          />
        </a>

        <_IconButton color="inherit" aria-label="menu" onClick={handleClick()}>
          <MenuIcon />
        </_IconButton>
      </_header>
    </>
  );
};
