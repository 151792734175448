import { NavLink } from '../types/types';
import { APP_URL } from './url.const';
import { faSignInAlt, faSignOutAlt, faCalculator, faUserCog, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faReduce } from '../icons/fa-reduce.icon';
import { faWindTurbine } from '@offsetter/atomic-components';

const login = {
  topLabel: 'Login',
  topUrl: APP_URL.LOGIN,
  topIcon: faSignInAlt,
  links: [],
};

const logout = {
  topLabel: 'Logout',
  topUrl: APP_URL.LOGOUT,
  topIcon: faSignOutAlt,
  links: [],
};

const calculate = {
  topLabel: 'Calculate',
  topUrl: APP_URL.CALCULATE,
  topIcon: faCalculator,
  links: [],
};

const calCompSummary = {
  topLabel: 'Calculate',
  topUrl: '',
  topIcon: faCalculator,
  links: [
    {
      label: 'Buildings',
      url: APP_URL.CALCULATE_COMPANY_BUILDINGS,
    },
    {
      label: 'Employees',
      url: APP_URL.CALCULATE_COMPANY_EMPLOYEES,
    },
    {
      label: 'Fleet',
      url: APP_URL.CALCULATE_COMPANY_FLEET,
    },
    {
      label: 'Flights',
      url: APP_URL.CALCULATE_COMPANY_FLIGHTS,
    },
  ],
};

const recalculate = {
  topLabel: 'Calculate',
  topUrl: APP_URL.RECALCULATE,
  topIcon: faCalculator,
  links: [],
};

const reduce = {
  topLabel: 'Reduce',
  topUrl: APP_URL.REDUCE,
  topIcon: faReduce,
  links: [],
};

// const report = {
//   topLabel: 'Report',
//   topUrl: APP_URL.REPORT,
//   topIcon: faShareSquare,
//   links: [],
// };

const action = {
  topLabel: 'Take action',
  topUrl: APP_URL.ACTION,
  topIcon: faWindTurbine,
  links: [],
};

const track = {
  topLabel: 'Track',
  topUrl: APP_URL.TRACK,
  topIcon: faChartBar,
  links: [],
};

const userDetails = {
  topLabel: 'My account',
  topUrl: APP_URL.USER_DETAILS,
  topIcon: faUserCog,
  links: [],
};

export const NAV_LINKS_AUTHENTICATED: NavLink[] = [recalculate, reduce, track, action, userDetails, logout];
export const NAV_LINKS_AUTH_COMPANY: NavLink[] = [calCompSummary];
export const NAV_LINKS_UNAUTHENTICATED: NavLink[] = [calculate, login];
