const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const debug = urlParams.get('debug');

const enableDebug = process.env.REACT_APP_ENABLE_DEBUG === 'true' || debug === 'true';
const appVersion = process.env.REACT_APP_VERSION || 'no-version-set';

export const Settings = {
  appVersion,
  enableDebug,
};
