import { Helmet } from 'react-helmet-async';

export const setPageTitle = (newTitle: string) => {
  const titleWithAppName = newTitle ? `${newTitle} | Offsetter` : 'Offsetter';

  return (
    <Helmet>
      <title>{titleWithAppName}</title>
    </Helmet>
  );
};
