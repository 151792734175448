import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Drawer } from '@material-ui/core';
import { NavLink } from '../../../types/types';
import { SideNavLinks } from '../../atom/carbon-equivalent-item/side-nav-links/side-nav-links.atom';
import { WEBSITE_URL } from '../../../constant/url.const';

const _a = styled.a`
  padding: 3rem 0rem 2rem 3.4rem;
  & > img {
    height: 2.8rem;
  }
`;

const _ul = styled.ul`
  width: 80vw;
  max-width: 24rem;
`;

export interface SideNavProps {
  navLinks: NavLink[];
  isOpen: boolean;
  isFixed: boolean;
  handleOpen: (open: boolean) => void;
}

export const SideNav = (props: SideNavProps) => {
  const { navLinks, isOpen, isFixed, handleOpen } = props;
  const [openDrawer, setOpenDrawer] = useState(isOpen);

  useEffect(() => {
    setOpenDrawer(isOpen);
  }, [isOpen]);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpenDrawer(open);
    handleOpen(open);
  };

  const renderList = () => {
    return (
      <>
        <_a href={WEBSITE_URL}>
          <img src="https://cdn.offsetter.io/logo/offsetter-logo-horizontal-green.svg" alt="Offsetter logo" />
        </_a>

        <_ul onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          {navLinks.map((navLink) => (
            <SideNavLinks navLink={navLink} key={navLink.topLabel} />
          ))}
        </_ul>
      </>
    );
  };
  return (
    <div>
      {!isFixed && (
        <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer(false)}>
          {renderList()}
        </Drawer>
      )}
      {isFixed && (
        <Drawer variant="permanent" anchor="left">
          {renderList()}
        </Drawer>
      )}
    </div>
  );
};
