import React, { lazy, ReactNode } from 'react';
import { APP_URL, WEBSITE_URL } from '../../constant/url.const';

const ActionTemplate = lazy(() => import('../../template/action/action.template'));
const ArticlePage = lazy(() => import('../../template/article/article'));

const CalCompBuildingsTemplate = lazy(() => import('../../template/company/calculate/buildings/buildings.template'));
const CalCompFleetTemplate = lazy(() => import('../../template/company/calculate/fleet/fleet.template'));
const CalCompEmployeeTemplate = lazy(() => import('../../template/company/calculate/employees/employees.template'));
const CalCompFlightTemplate = lazy(() => import('../../template/company/calculate/flights/flight.template'));

const DeleteConfirmation = lazy(() => import('../../template/delete-confirmation/delete-confirmation'));
const FeedPage = lazy(() => import('../../template/feed/feed'));
const FootprintCalculator = lazy(() => import('../../template/footprint-calculator/footprint-calculator'));
const Login = lazy(() => import('../../template/login/login'));
const Logout = lazy(() => import('../../template/logout/logout'));
const PaymentDetails = lazy(() => import('../../template/payment-details/payment-details'));
const SummaryTemplate = lazy(() => import('../../template/summary/summary.template'));
const ReportTemplate = lazy(() => import('../../template/report/report.template'));
const TrackTemplate = lazy(() => import('../../template/track/track.template'));
const ThankYou = lazy(() => import('../../template/thank-you/thank-you'));
const UpdateConfirmation = lazy(() => import('../../template/update-confirmation/update-confirmation'));
const UpdateFootprint = lazy(() => import('../../template/update-footprint/update-footprint'));
const UserDetails = lazy(() => import('../../template/user-details/user-details'));

export type Page = {
  path: string;
  pageTitle: string;
  component: ReactNode;
  isPrivate: boolean;
  backNavTarget?: string;
};

export const pages: Page[] = [
  {
    path: APP_URL.HOME,
    pageTitle: 'Calculate your footprint',
    component: <FootprintCalculator />,
    isPrivate: false,
    backNavTarget: WEBSITE_URL,
  },
  {
    path: APP_URL.CALCULATE_COMPANY_BUILDINGS,
    pageTitle: 'Calculate your company building footprint',
    component: <CalCompBuildingsTemplate />,
    isPrivate: true,
  },
  {
    path: APP_URL.CALCULATE_COMPANY_EMPLOYEES,
    pageTitle: 'Calculate your company employees footprint',
    component: <CalCompEmployeeTemplate />,
    isPrivate: true,
  },
  {
    path: APP_URL.CALCULATE_COMPANY_FLIGHTS,
    pageTitle: 'Calculate your company flights footprint',
    component: <CalCompFlightTemplate />,
    isPrivate: true,
  },
  {
    path: APP_URL.CALCULATE_COMPANY_FLEET,
    pageTitle: 'Calculate your company fleet footprint',
    component: <CalCompFleetTemplate />,
    isPrivate: true,
  },
  {
    path: APP_URL.SUMMARY,
    pageTitle: 'Summary',
    component: <SummaryTemplate />,
    isPrivate: false,
    backNavTarget: APP_URL.HOME,
  },
  {
    path: APP_URL.ACTION,
    pageTitle: 'Take Action',
    component: <ActionTemplate />,
    isPrivate: true,
  },
  {
    path: APP_URL.PAYMENTS,
    pageTitle: 'Payment details',
    component: <PaymentDetails />,
    isPrivate: true,
  },
  {
    path: APP_URL.LOGIN,
    pageTitle: 'Login',
    component: <Login />,
    isPrivate: true,
  },
  {
    path: APP_URL.LOGOUT,
    pageTitle: 'Logout',
    component: <Logout />,
    isPrivate: true,
  },
  {
    path: APP_URL.USER_DETAILS,
    pageTitle: 'User details',
    component: <UserDetails />,
    isPrivate: true,
  },
  {
    path: APP_URL.RECALCULATE,
    pageTitle: 'Update your footprint',
    component: <UpdateFootprint />,
    isPrivate: true,
    backNavTarget: '/user-details',
  },
  {
    path: APP_URL.FOOTPRINT_UPDATED,
    pageTitle: 'Footprint updated',
    component: <UpdateConfirmation />,
    isPrivate: true,
  },
  {
    path: APP_URL.THANK_YOU,
    pageTitle: 'Thank you',
    component: <ThankYou />,
    isPrivate: true,
  },
  {
    path: APP_URL.DELETE,
    pageTitle: 'Account deleted',
    component: <DeleteConfirmation />,
    isPrivate: false,
  },
  {
    path: APP_URL.REDUCE,
    pageTitle: 'Reduce your footprint',
    component: <FeedPage />,
    isPrivate: true,
    backNavTarget: APP_URL.USER_DETAILS,
  },
  {
    path: APP_URL.ARTICLE,
    pageTitle: 'Reduce your footprint',
    component: <ArticlePage />,
    isPrivate: true,
    backNavTarget: APP_URL.REDUCE,
  },
  {
    path: APP_URL.REPORT,
    pageTitle: 'Report and share your progress',
    component: <ReportTemplate />,
    isPrivate: true,
  },
  {
    path: APP_URL.TRACK,
    pageTitle: 'Track your progress',
    component: <TrackTemplate />,
    isPrivate: true,
  },
];
