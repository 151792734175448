import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MaterialUiLink from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { isAbsoluteUrl } from '../../../util/url.util';

const Section = styled.section`
  ${({ theme }) => `
    padding-bottom: ${theme.spacing(4)};
  `}
`;
interface ErrorScreenProps {
  message: string;
  actionText?: string;
  actionPath?: string;
}

const ErrorScreen = (props: ErrorScreenProps) => {
  const { actionText, actionPath, message } = props;

  const renderAction = () => {
    if (!actionText) {
      return null;
    }

    if (actionPath) {
      const actionButton = <Button variant="contained" color="secondary">{actionText}</Button>;
      if (isAbsoluteUrl(actionPath)) {
        return <a data-testid="action-link" href={actionPath}>{actionButton}</a>
      }

      return <Link data-testid="action-link" to={actionPath}>{actionButton}</Link>;
    }

    return <Typography variant="body1">{actionText}</Typography>;
  };

  return (
    <>
      <Section>
        <Typography variant="body1">{message}</Typography>
        <Typography variant="body1">If the problem persists, <MaterialUiLink data-testid="support-link" href="mailto:support@offsetter.io" target="_blank" rel="noreferrer">let us know</MaterialUiLink>.</Typography>
        {renderAction()}
      </Section>
    </>
  );
};

export default ErrorScreen;

