import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAuth0 } from '@auth0/auth0-react';
import { Header } from '../header/header.organism';
import { SideNav } from '../../molecule/side-nav/side-nav.molecule';
import { NAV_LINKS_AUTHENTICATED, NAV_LINKS_UNAUTHENTICATED } from '../../../constant/links.const';
import { BREAKPOINT } from '../../../constant/app.constant';

const _div = styled.div`
  ${({ theme }) => `
    ${theme.breakpoints.up(BREAKPOINT.layoutDesktop)} {
      padding-left: 24rem;
      width: 100%;
    }
  `}
`;

const _main = styled.main`
  margin: 0 auto;

  ${({ theme }) => `
    max-width: 96rem;
    padding: ${theme.spacing(6)} ${theme.spacing(3)};
    background: ${theme.palette.monochrome.light};

    ${theme.breakpoints.down(400)} {
      padding: ${theme.spacing(4)} ${theme.spacing(2.2)};
    }
  `}
`;

interface Props {
  backNavTarget?: string;
  children: ReactNode;
}

export const PageWrapper: React.FC<Props> = ({ backNavTarget, children }: Props) => {
  const { isAuthenticated } = useAuth0();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navLinks = isAuthenticated ? NAV_LINKS_AUTHENTICATED : NAV_LINKS_UNAUTHENTICATED;
  const isDesktop = useMediaQuery(`(min-width:${BREAKPOINT.layoutDesktop}px)`);

  const handleOpen = (isOpen: boolean) => {
    setIsMenuOpen(isOpen);
  };

  return (
    <div>
      {!isDesktop && <Header backNavTarget={backNavTarget} handleMenuToggle={handleOpen} />}
      <SideNav navLinks={[...navLinks]} isOpen={isMenuOpen} handleOpen={handleOpen} isFixed={isDesktop} />

      <_div>
        <_main>{children}</_main>
      </_div>
    </div>
  );
};
