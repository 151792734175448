import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export const faReduce: IconDefinition = {
  prefix: 'fab',
  iconName: 'sith',
  icon: [
    512,
    384,
    [],
    'e001',
    'M496,320L64,320L64,16C64,7.16 56.84,0 48,0L16,0C7.16,0 0,7.16 0,16L0,352C0,369.67 14.33,384 32,384L496,384C504.84,384 512,376.84 512,368L512,336C512,327.16 504.84,320 496,320ZM372.418,197.799L287.637,116.64L209.764,195.217C197.264,207.717 192.218,208.951 179.728,196.451L111.678,129.171C105.428,122.921 104.803,115.483 111.053,109.233L122.492,97.151C128.742,90.901 138.872,90.901 145.122,97.151L191.182,143.221L264.552,69.851C277.052,57.351 297.312,57.351 309.802,69.851L404.884,164.933L430.911,138.584C442.742,126.607 462.967,135.091 462.967,152.025L462.967,245.531C462.975,252.533 457.373,258.204 450.456,258.204L358.084,258.204C341.355,258.204 332.976,237.729 344.806,225.752L372.418,197.799Z',
  ],
};
