import React from "react";

const LoadingScreen = () => {
    return (
      <section data-testid="loading-message">
        <p>Loading...</p>
      </section>
    );
};

export default LoadingScreen;