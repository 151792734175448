import { APP } from '../../constant/app.constant';
import { createGlobalState } from 'react-hooks-global-state';
import { CalculationResult } from '../../util/api/api.handler';

import { getInitialUserState } from './user.state';
import { logger } from '../../util/logger/logger';
import { Equivalency } from '../../types/types';
import { FootprintCalcValues, OffsetDetails, SupportedCurrency, FootprintUpdateChanges } from '../types/app.types';
import { getInitialRecommendedArticlesState } from './recommendation.state';
import { getInitialArticlesState } from './content.state';
import { getInitialOffsetHistoryState } from './offset-history.state';
import { getInitialCarbonEquivalencyState } from './carbon-equivalency.state';
import parseFootprintCalcParams from '../../util/carbon-footprint/footprintCalcParamsParser';

export const getCalculatorFootprintValues = (): FootprintCalcValues => {
  return {
    footprintCountry: getGlobalState('calculatorCountry'),
    footprintScoreDiet: getGlobalState('calculatorScoreDiet'),
    footprintScoreEnergy: getGlobalState('calculatorScoreEnergy'),
    footprintScoreTransport: getGlobalState('calculatorScoreTransport'),
    footprintShortFlights: getGlobalState('calculatorShortFlights'),
    footprintLongFlights: getGlobalState('calculatorLongFlights'),
    offsetPercentage: getGlobalState('calculatorOffsetPercentage'),
  };
};

export const getCalculatorOffsetDetails = (): OffsetDetails => {
  return {
    footprintCountry: getGlobalState('calculatorCountry'),
    footprintScoreDiet: getGlobalState('calculatorScoreDiet'),
    footprintScoreTransport: getGlobalState('calculatorScoreTransport'),
    footprintScoreEnergy: getGlobalState('calculatorScoreEnergy'),
    footprintShortFlights: getGlobalState('calculatorShortFlights'),
    footprintLongFlights: getGlobalState('calculatorLongFlights'),
    monthlyFootprintInTonnes: getGlobalState('calculatorMonthlyFootprintInTonnes'),
    offsetPercentage: getGlobalState('calculatorOffsetPercentage'),
    offsetCost: getGlobalState('calculatorOffsetCost'),
    offsetCurrency: getGlobalState('calculatorOffsetCurrency') as SupportedCurrency,
    systemsChangePercentage: getGlobalState('calculatorSystemsChangePercentage'),
    systemsChangeOrgIds: getGlobalState('calculatorSystemsChangeOrgIds'),
  };
};

export const setCalculatorOffsetDetails = (offsetDetails: OffsetDetails) => {
  setGlobalState('user', getInitialState().user);
  setGlobalState('calculatorCountry', offsetDetails.footprintCountry);
  setGlobalState('calculatorScoreDiet', offsetDetails.footprintScoreDiet);
  setGlobalState('calculatorScoreTransport', offsetDetails.footprintScoreTransport);
  setGlobalState('calculatorScoreEnergy', offsetDetails.footprintScoreEnergy);
  setGlobalState('calculatorShortFlights', offsetDetails.footprintShortFlights);
  setGlobalState('calculatorLongFlights', offsetDetails.footprintLongFlights);
  setGlobalState('calculatorMonthlyFootprintInTonnes', offsetDetails.monthlyFootprintInTonnes);
  setGlobalState('calculatorOffsetPercentage', offsetDetails.offsetPercentage);
  setGlobalState('calculatorOffsetCost', offsetDetails.offsetCost);
  setGlobalState('calculatorOffsetCurrency', offsetDetails.offsetCurrency);
  setGlobalState('calculatorSystemsChangePercentage', offsetDetails.systemsChangePercentage);
  setGlobalState('calculatorSystemsChangeOrgIds', offsetDetails.systemsChangeOrgIds);
};

export const setCalculationResult = (calculationResult: CalculationResult) => {
  setGlobalState('calculatorMonthlyFootprintInTonnes', calculationResult.monthlyFootprintInTonnes);
  setGlobalState('calculatorOffsetCost', calculationResult.offsetCost);
  setGlobalState('calculatorOffsetCurrency', calculationResult.offsetCurrency as SupportedCurrency);
};

export const setCarbonEquivalency = (equivalency: Equivalency[]) => {
  setGlobalState('allCarbonEquivalency', equivalency);
};

export const setPositiveCarbonEquivalency = (equivalency: Equivalency[]) => {
  setGlobalState('positiveCarbonEquivalency', equivalency);
};

export const setNeutralCarbonEquivalency = (equivalency: Equivalency[]) => {
  setGlobalState('neutralCarbonEquivalency', equivalency);
};

export const setNegativeCarbonEquivalency = (equivalency: Equivalency[]) => {
  setGlobalState('negativeCarbonEquivalency', equivalency);
};

export const updateSavedOffsetDetails = (newSavedOffsetDetails: OffsetDetails) => {
  const currentSavedOffsetDetails = getGlobalState('savedOffsetDetails');
  if (currentSavedOffsetDetails) {
    setGlobalState('footprintUpdateChanges', {
      footprintDifference:
        newSavedOffsetDetails.monthlyFootprintInTonnes - currentSavedOffsetDetails.monthlyFootprintInTonnes,
      offsetCostDifference: newSavedOffsetDetails.offsetCost - currentSavedOffsetDetails.offsetCost,
      offsetPercentageDifference: newSavedOffsetDetails.offsetPercentage - currentSavedOffsetDetails.offsetPercentage,
    });
  }

  setGlobalState('savedOffsetDetails', newSavedOffsetDetails);
};

const urlParamFootprintValues = parseFootprintCalcParams(window.location.search);

export const getInitialState = () => {
  return {
    user: getInitialUserState(),
    calculatorCountry: urlParamFootprintValues.footprintCountry || APP.defaultCountryCode,
    calculatorScoreDiet: urlParamFootprintValues.footprintScoreDiet || APP.defaultFootprintScore,
    calculatorScoreTransport: urlParamFootprintValues.footprintScoreTransport || APP.defaultFootprintScore,
    calculatorScoreEnergy: urlParamFootprintValues.footprintScoreEnergy || APP.defaultFootprintScore,
    calculatorShortFlights: urlParamFootprintValues.footprintShortFlights || APP.defaultFlightsAmount,
    calculatorLongFlights: urlParamFootprintValues.footprintLongFlights || APP.defaultFlightsAmount,
    calculatorMonthlyFootprintInTonnes: APP.defaultCarbonTonnesMonth,
    calculatorOffsetPercentage: urlParamFootprintValues.offsetPercentage || APP.defaultOffsetPercentage,
    calculatorOffsetCost: APP.defaultOffsetCost,
    calculatorOffsetCurrency: APP.defaultOffsetCurrency as SupportedCurrency,
    calculatorSystemsChangePercentage: urlParamFootprintValues.systemsChangePercentage || APP.defaultCalculatorSystemsChangePercentage,
    calculatorSystemsChangeOrgIds: urlParamFootprintValues.systemsChangeOrgIds || APP.defaultCalculatorSystemsChangeOrgIds as string[],
    footprintUpdateChanges: null as FootprintUpdateChanges | null,
    redirectedFromAuthProvider: false,
    savedOffsetDetails: null as OffsetDetails | null,
    recommendedArticles: getInitialRecommendedArticlesState(),
    articles: getInitialArticlesState(),
    offsetHistory: getInitialOffsetHistoryState(),
    allCarbonEquivalency: getInitialCarbonEquivalencyState(),
    positiveCarbonEquivalency: getInitialCarbonEquivalencyState(),
    neutralCarbonEquivalency: getInitialCarbonEquivalencyState(),
    negativeCarbonEquivalency: getInitialCarbonEquivalencyState(),
  };
};

logger.debug('InitialState', getInitialState());

const { useGlobalState, getGlobalState, setGlobalState } = createGlobalState(getInitialState());

export { useGlobalState, getGlobalState, setGlobalState };
