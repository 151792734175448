import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { IconButton } from '@material-ui/core';
import { NavLink } from '../../../../types/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';

const sharedLinkStyle = css`
  &:link,
  &:visited {
    ${({ theme }) => `
    color: ${theme.palette.shadow.dark};
  `}
  }

  &:hover {
    ${({ theme }) => `
    color: ${theme.palette.secondary.main};
  `}
  }

  &.active {
    ${({ theme }) => `
    background-color: ${theme.palette.secondary.main};
    color: ${theme.palette.monochrome.light};
  `}
  }
`;

const _Link = styled(Link)`
  height: 4.8rem;
  padding: 0.8rem 0 0.8rem 2.8rem;
  cursor: pointer;
  display: flex;
  font-size: 1.8rem;
  align-items: center;

  & > :first-child {
    margin-right: 0.8rem;
  }

  ${sharedLinkStyle}
`;

const _ul = styled.ul`
  display: none;
  &.open {
    display: block;
  }
`;

const _span = styled.span`
  width: 3rem;
`;

const _LinkNested = styled(Link)`
  display: block;
  padding: 0.8rem 2.4rem 0.8rem 6.6rem;

  ${sharedLinkStyle}
`;

export interface SideNavLinksProps {
  navLink: NavLink;
}

export const SideNavLinks = (props: SideNavLinksProps) => {
  const { navLink } = props;
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const toggleExpand = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (navLink.links.length > 0) {
      event.preventDefault();
      event.stopPropagation();

      setOpen(!open);
    }
  };

  useEffect(() => {
    if (navLink.links.length > 0) {
      navLink.links.forEach((link) => {
        if (link.url === location.pathname) {
          setOpen(true);
        }
      });
    }
  }, [location.pathname, navLink.links]);

  return (
    <li key={navLink.topLabel}>
      <_Link
        to={navLink.topUrl}
        onClick={toggleExpand()}
        className={location.pathname === navLink.topUrl ? 'active' : ''}
      >
        <_span>
          <FontAwesomeIcon icon={navLink.topIcon} />
        </_span>
        {navLink.topLabel}
        {navLink.links.length > 0 && (
          <IconButton color="inherit">
            {!open && <ExpandMoreIcon />}
            {open && <ExpandLessIcon />}
          </IconButton>
        )}
      </_Link>
      {navLink.links.length > 0 && (
        <_ul className={open ? 'open' : ''}>
          {navLink.links.map((link) => {
            return (
              <li key={link.label}>
                <_LinkNested to={link.url} className={location.pathname === link.url ? 'active' : ''}>
                  {link.label}
                </_LinkNested>
              </li>
            );
          })}
        </_ul>
      )}
    </li>
  );
};
