import { User } from '@auth0/auth0-react/dist/auth-state';

const IS_PROVISIONAL_USER_KEY = 'https://offsetter.io/is-provisional';
const IS_IN_NL_STRIPE_USER_KEY = 'https://offsetter.io/is-in-nl-stripe';
const IS_IN_IE_STRIPE_USER_KEY = 'https://offsetter.io/is-in-ie-stripe';

/**
 * Checks whether the provided user is both logged in and a full, non-provisional user.
 * A user is "provisional" when they have created an account in Auth0, but not yet 
 * created a subscription and made an initial payment with Stripe.
 * 
 * @param user the user to check, undefined is considered not logged in
 */
export const isLoggedInFullUser = (user?: User) => {
  return isLoggedIn(user) && !isProvisional(user);
}

/**
 * Checks whether the provided user is both logged in and a provisional (not full) user.
 * A user is "provisional" when they have created an account in Auth0, but not yet 
 * created a subscription and made an initial payment with Stripe.
 * 
 * @param user the user to check, undefined is considered not logged in
 */
export const isLoggedInProvisionalUser = (user?: User) => {
  return isLoggedIn(user) && isProvisional(user);
}

const isLoggedIn = (user?: User) => {
  return user !== undefined && user !== false;
}

const isProvisional = (user: User) => {
  return user[IS_PROVISIONAL_USER_KEY] === true;
}

/**
 * Checks whether the provided user has billing history in our Dutch Stripe account. This is 
 * the case when a user's account was created before Offsetter was transferred from a Dutch to 
 * an Irish entity in September 2021.
 * 
 * @param user the user to check, returns false if the user is not logged in
 */
export const isInNlStripe = (user?: User) => {
  return isLoggedIn(user) && user[IS_IN_NL_STRIPE_USER_KEY] === true;
};

/**
 * Checks whether the provided user has billing history in our Irish Stripe account. This is 
 * the case when a user account had an active subscription in the period when Offsetter was 
 * an Irish entity between September 2021 and August 2022.
 * 
 * @param user the user to check, returns false if the user is not logged in
 */
 export const isInIeStripe = (user?: User) => {
  return isLoggedIn(user) && user[IS_IN_IE_STRIPE_USER_KEY] === true;
};