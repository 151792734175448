import Rollbar from 'rollbar';

const rollbarAccessToken = '7a007b06f5064dfaa4dcb8b5b30eb6a8';
const environment = process.env.REACT_APP_ENVIRONMENT_NAME;

const supportedErrorEnvironments = ['dev', 'prd'];

let rollbarClient: Rollbar | null = null;

export const reportError = (error: Error | string) => {
  if (supportedErrorEnvironments.includes(environment || '')) {
    getRollbarClient().error(error);
  }
}

const getRollbarClient = () => {
  if (!rollbarClient) {
    rollbarClient = new Rollbar({
      accessToken: rollbarAccessToken,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment
      }
    });
  }

  return rollbarClient;
}
