export const BASE_URL = process.env.REACT_APP_URL_BASE || '';
export const WEBSITE_URL = process.env.REACT_APP_URL_WEBSITE || '';

export const APP_URL = {
  ACTION: '/action',
  ARTICLE: '/article/:articleId',
  CALCULATE: '/',
  CALCULATE_COMPANY_BUILDINGS: '/calculate/company/buildings',
  CALCULATE_COMPANY_EMPLOYEES: '/calculate/company/emploees',
  CALCULATE_COMPANY_FLIGHTS: '/calculate/company/flights',
  CALCULATE_COMPANY_FLEET: '/calculate/company/fleet',
  DELETE: '/account-deleted',
  FOOTPRINT_UPDATED: '/footprint-updated',
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  PAYMENTS: '/payment-details',
  REDUCE: '/reduce',
  RECALCULATE: '/update-footprint',
  REPORT: '/report',
  SUMMARY: '/summary',
  TRACK: '/track',
  THANK_YOU: '/thank-you',
  USER_DETAILS: '/user-details',
};
