import React, { StrictMode, Suspense } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Auth0Provider } from '@auth0/auth0-react';
import { setGlobalState, setCalculatorOffsetDetails } from '../app/state/app.state';
import LoadingScreen from '../component/organism/loading-screen/loading-screen.organism';
import { AppProvider } from './providers/app.provider';
import { Routes } from './routes/routes';
import { ErrorBoundary } from './error-boundary/error-boundary';
import { logger } from '../util/logger/logger';
import { Settings } from './settings';

export const history = createBrowserHistory();

const App: React.FC = () => {
  logger.info('App Version', Settings.appVersion);

  const onRedirectCallback = (appState?: any) => {
    if (appState && appState.offsetDetails) {
      setGlobalState('redirectedFromAuthProvider', true);
      setCalculatorOffsetDetails(appState.offsetDetails);
    }

    let redirectUrl;
    if (appState && appState.targetUrl) {
      redirectUrl = appState.targetUrl;
    } else if (appState && appState.returnTo) {
      redirectUrl = appState.returnTo;
    } else {
      redirectUrl = window.location.pathname;
    }
    history.push(redirectUrl);
  };

  return (
    <StrictMode>
      <ErrorBoundary>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN!}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
          redirectUri={window.location.origin}
          onRedirectCallback={onRedirectCallback}
          audience={process.env.REACT_APP_BACKEND_URL!}
          scope="create:offsetter read:offsetter read:offset_history update:offsetter delete:offsetter create:stripe_customer_portal_url create:ie_stripe_customer_portal_url"
        >
          <Router history={history}>
            <Suspense fallback={<LoadingScreen />}>
              <AppProvider>
                <Routes />
              </AppProvider>
            </Suspense>
          </Router>
        </Auth0Provider>
      </ErrorBoundary>
    </StrictMode>
  );
};

export default App;
