import React from 'react';
import Auth0Wrapper from '../../util/auth0Wrapper';
import { Route, Switch } from 'react-router-dom';
import { PageWrapper } from '../../component/organism/page-wrapper/page-wrapper.organism';
import { useAnalytics } from '../../hook/analytics/analytics.hook';
import { setPageTitle } from '../../util/page.util';
import { Page, pages } from './pages';

type RenderPageProps = {
  page: Page;
};

const RenderPage = (props: RenderPageProps) => {
  const { page } = props;
  useAnalytics();

  return (
    <>
      {setPageTitle(page.pageTitle)}
      {page.isPrivate && (
        <Auth0Wrapper>
          <PageWrapper backNavTarget={page.backNavTarget}>{page.component}</PageWrapper>
        </Auth0Wrapper>
      )}
      {!page.isPrivate && <PageWrapper backNavTarget={page.backNavTarget}>{page.component}</PageWrapper>}
    </>
  );
};

export const Routes = () => {
  return (
    <Switch>
      {pages.map((page: Page) => (
        <Route exact path={page.path} key={page.path}>
          <RenderPage page={page} />
        </Route>
      ))}
    </Switch>
  );
};
