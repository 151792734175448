import React, { ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@offsetter/atomic-components';

interface Props {
  children: ReactNode;
}

export const AppProvider: React.FC<Props> = ({ children }: Props) => {
  return (
    <HelmetProvider>
      <ThemeProvider>{children}</ThemeProvider>
    </HelmetProvider>
  );
};
