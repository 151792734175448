import queryString from 'query-string';
import { FootprintCalcValues, SystemsChangeValues } from '../../app/types/app.types';
import { APP } from '../../constant/app.constant';
import { logger } from '../logger/logger';

const parseFootprintCalcParams = (queryParamsString: string): Partial<FootprintCalcValues & SystemsChangeValues> => {
  const queryParams = queryString.parse(queryParamsString);
  return validateAndParse(queryParams);
};

const validateAndParse = (queryParams: any): Partial<FootprintCalcValues & SystemsChangeValues> => {
  return {
    footprintCountry: validateCountryCode(queryParams.c) ? queryParams.c : undefined,
    footprintScoreDiet: parseFootprintScore(queryParams.d),
    footprintScoreTransport: parseFootprintScore(queryParams.t),
    footprintScoreEnergy: parseFootprintScore(queryParams.e),
    footprintShortFlights: parseFootprintScore(queryParams.s),
    footprintLongFlights: parseFootprintScore(queryParams.l),
    offsetPercentage: parsePercentageValue(queryParams.p, 'offset percentage'),
    systemsChangePercentage: parsePercentageValue(queryParams.scp, 'systems change percentage'),
    systemsChangeOrgIds: parseSystemsChangeOrgIds(queryParams.sco)
  };
};

const validateCountryCode = (countryCode: string) => {
  return countryCode && countryCode.length === 2 && /^[a-zA-Z]+$/.test(countryCode);
};

const parseFootprintScore = (footprintScoreString: string) => {
  if (footprintScoreString === undefined) {
    return undefined;
  }

  const footprintScore = parseInt(footprintScoreString, 10);

  if (isNaN(footprintScore)) {
    logger.error(`'${footprintScoreString}' is not a valid footprint score`);
    return undefined;
  }

  if (footprintScore < APP.minFootprintScore || footprintScore > APP.maxFootprintScore) {
    logger.error(
      `${footprintScoreString} parsed from a URL parameter is not a valid footprint score. A footprint score must be between ${APP.minFootprintScore} and ${APP.maxFootprintScore}.`
    );
    return undefined;
  }

  return footprintScore;
};

const parsePercentageValue = (percentageString: string, paramName: string) => {
  if (percentageString === undefined) {
    return undefined;
  }

  const percentage = parseInt(percentageString, 10);

  if (isNaN(percentage) || percentage < 0 || percentage > 100) {
    logger.error(`'${percentageString}' parsed from a URL parameter is not a valid ${paramName}`);
    return undefined;
  }

  return percentage;
};

const parseSystemsChangeOrgIds = (orgIdsParam: string) => {
  if (!orgIdsParam) {
    return undefined;
  }

  return orgIdsParam.split(',');
}

export default parseFootprintCalcParams;