export const APP = {
  defaultCountryCode: 'NL',
  defaultFootprintScore: 50,
  minFootprintScore: 0,
  maxFootprintScore: 100,
  defaultFlightsAmount: 0,
  defaultOffsetPercentage: 100,
  defaultCarbonTonnesMonth: 0.7546479596666668, // based on default values for NL
  defaultOffsetCost: 1762,
  defaultOffsetCurrency: 'EUR',
  defaultCalculatorSystemsChangePercentage: 40,
  defaultCalculatorSystemsChangeOrgIds: [],
};

export const FONT_SIZE = {
  extraSmall: '1.2rem',
  small: '1.4rem',
  default: '1.6rem',
  medium: '2rem',
  large: '3.2rem',
  extraLarge: '4.8rem',
};

export const BREAKPOINT = {
  deviceXs: 0,
  deviceSm: 600,
  deviceMd: 960,
  deviceLg: 1280,
  deviceXl: 1920,
  layoutDesktop: 1060,
};

export const ROLES = {
  default: 'individual',
  individual: 'individual',
  companyAdmin: 'company-admin',
  companyEmployee: 'company-employee',
};
