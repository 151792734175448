import React from 'react';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import LoadingScreen from '../component/organism/loading-screen/loading-screen.organism';
import ErrorScreen from '../component/organism/error-screen/error-screen.organism';
import { getGlobalState, setGlobalState } from '../app/state/app.state';
import { ROLES } from '../constant/app.constant';
import { logger } from './logger/logger';
import { useLocation } from 'react-router-dom';

interface WrapperProps {
  children: React.ReactNode;
}

function Auth0Wrapper({ children }: WrapperProps) {
  const { isLoading, error, user } = useAuth0();
  const location = useLocation();
  const appUser = getGlobalState('user');

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorScreen message={`Auth0 error: ${error.message}`} />;
  }

  setGlobalState('user', { ...appUser, email: user.email, role: getRole(location.search) });
  return <>{children}</>;
}

export default withAuthenticationRequired(Auth0Wrapper, {
  onRedirecting: () => <LoadingScreen />,
});

/*
 This SHOULD be updated to get the role from the
 Auth0 JWT. For now a flag on the url is being used
*/
const getRole = (locationSearch: string) => {
  let params = new URLSearchParams(locationSearch);

  let role = params.get('role');
  if (role && Object.values(ROLES).includes(role)) {
    return role;
  } else {
    logger.warn(`User role '${role}' is not valid. Using default role '${ROLES.default}'`);
    return ROLES.default;
  }
};
